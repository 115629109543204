.wrapper {
  position: relative;
  height: 100%;
}

.wrapper > * {
  position: relative;
  z-index: 11;
}

.wave {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 33px;
  mask-size: 100% 31px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 31px;
}
