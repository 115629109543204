:root {
  /* fonts */
  --font-family-primary:
    'DM Sans',
    'Helvetica Neue',
    helvetica,
    arial,
    sans-serif;
  --font-family-secondary: 'Rubik', -apple-system, 'Helvetica Neue', sans-serif;

  /* colors - synced to lib/theme.js */
  --colors-primary-400: #f8284e;
  --colors-secondary-100: #f8f8f9;
  --colors-secondary-900: #27292d;
  --colors-secondary-300: #e1e2e9;
  --colors-secondary-200: #eff0f4;
  --colors-surface-800: #303136;
  --colors-surface-700: #55595e;
  --colors-surface-500: #95979f;
  --colors-surface-000: #fff;

  /* calculated height for ctaButton on videoPage */
  --cookie-banner: 66px;
  --cta-button: 78px;
  --full-screen-height: 100vh - 150px;
  --sticky-header-height: 56px;
}
