/* stylelint-disable selector-max-type */

@import '../antd/antd-custom.css';
@import './nprogress.css';
@import './vars.css';
@import '../components/UI/styles/globals.css';

html,
body {
  background: #eff0f4;
  letter-spacing: -0.04em;
  scroll-behavior: smooth;
  -webkit-font-feature-settings: normal;
  -moz-font-feature-settings: normal;
  font-feature-settings: normal;
}

/* Tweaks for react-phone-number-input together with ant design */
.PhoneInput {
  padding-right: 8px;
  padding-left: 12px;
  border: 1px solid #c9cbd6;
  border-radius: 4px;
  background: white;
}

.PhoneInputCountryIcon {
  position: relative;
  margin-right: 2px;
  box-shadow: none !important;
}

.PhoneInputCountryIconImg {
  position: absolute;
}

.PhoneInputInput {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  margin: 0;
  padding: 0 6px;
  border: 1px solid #d9d9d9;
  border: none;
  background-color: #fff;
  background-image: none;
  color: rgba(0, 0, 0, 0.65);
  outline: none;
  list-style: none;
  font-variant: tabular-nums;
  font-size: 16px;
  line-height: 1.5;

  /* border-radius: 4px; */
  transition: all 0.3s;
  font-feature-settings: 'tnum', 'tnum';
}

.PhoneInputInput:focus {
  outline: none;
}

.ant-input-number {
  width: 100%;
}

.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:active {
  color: #ff526c !important;
}

.ant-select-arrow {
  color: rgba(0, 0, 0, 0.65);
}

.ant-picker-header button {
  color: rgb(27, 28, 31);
}

/* Hack to remove Now-button in timepicker */
.deliver-at-popup .ant-picker-now-btn {
  display: none;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #1bb55c !important;
}
