/* stylelint-disable selector-max-type */

@import '../../../assets/vars.css';

:root {
  --primary-red: #f8284e;
  --primary-300-red: #ec5e71;
  --primary-900-red: #c10018;
  --secondary-dark: #27292d;
  --secondary-gray: #e1e2e9;
  --secondary-light: #eff0f4;
  --surface-gray: #95979f;
  --surface-white: #fff;
  --surface-dark: #55595e;
  --semantic-information: #0f56b3;
  --semantic-confirmation: #1bb55c;
  --semantic-error: #da100b;
  --semantic-warning: #ffbb12;
  --tertiary-brown-light: #df8633;
  --tertiary-brown-dark: #9d5e25;
  --tertiary-yellow-light: #fac921;
  --tertiary-goldenrod: #d1a91f;
  --tertiary-olive: #937616;
  --tertiary-greenyellow: #98d11f;
  --tertiary-yellowgreen: #6a9216;
  --tertiary-green-light: #1fd15c;
  --tertiary-green-dark: #169242;
  --tertiary-cyan-light: #3edae4;
  --tertiary-cyan-dark: #2c98a5;
  --tertiary-skyblue-light: #5dace8;
  --tertiary-skyblue-dark: #4178a7;
  --tertiary-blue: #3e63e4;
  --tertiary-blue-dark: #2c44a5;
  --tertiary-violet: #9b3ee4;
  --tertiary-violet-dark: #6d2ba5;
  --tertiary-hotpink: #e43ebf;
  --tertiary-purple: #a02b8a;
  --tertiary-salmon: #e43e66;
  --tertiary-red-dark: #a02b4a;
  --tertiary-gray: #757380;
  --tertiary-gray-dark: #52505d;
  --tertiary-gray-default: #636b78;
  --tertiary-gray-light: #d1d4d6;
  --space-large: 45px;
  --input-white: #f8f8f9;
  --font-size-h1: 48px;
  --font-size-h2: 40px;
  --font-size-h3: 32px;
  --font-size-h4: 28px;
  --font-size-h5: 24px;
  --font-size-h6: 18px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
}
