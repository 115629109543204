.searchButton:global(.ant-btn-link) {
  display: flex;
  align-items: center;
  padding-right: 18px;
  font-size: 16px;
}

.searchButtonText {
  display: none;
  padding-left: 8px;
  color: #fff;
}

.searchButtonDark .searchButtonText {
  color: var(--colors-secondary-900);
}

@media (min-width: 768px) {
  .searchButton:global(.ant-btn-link svg) {
    font-size: 24px;
  }

  .searchButton:global(.ant-btn-link.ant-btn-background-ghost):hover {
    color: #fff !important; /* because antd use !important */
  }

  .searchButtonDark:global(.ant-btn-link.ant-btn-background-ghost):hover {
    color: var(--colors-secondary-900) !important; /* because antd use !important */
  }

  .searchButtonText {
    display: block;
  }
}
