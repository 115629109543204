.contentWrapper {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.label {
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
}

.label:not(:first-child) {
  margin-left: 10px;
}

.xsmall .label {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.tiny .label {
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
}

.small .label:not(:first-child) {
  display: none;
}

.button {
  overflow: visible;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  text-transform: none;
  cursor: pointer;
  transition: 0.25s ease;
  transition-property: background, box-shadow;
}

.button:disabled {
  opacity: 0.3;
  cursor: default;
}

.primary {
  background: var(--primary-red);
  color: var(--surface-white);
}

.primary:active {
  background: var(--primary-900-red);
}

.secondary {
  background: transparent;
  color: var(--secondary-dark);
  box-shadow: 0 0 0 2px var(--secondary-dark) inset;
}

.secondary:active {
  box-shadow: 0 0 0 2px var(--primary-900-red) inset;
}

.inverted {
  background: var(--surface-white);
  color: var(--primary-red);
}

.inverted:active {
  background: var(--secondary-dark);
}

.black {
  background: var(--secondary-dark);
  color: var(--surface-white);
}

.black:active {
  background: #000;
}

.large {
  width: 100%;
  height: 48px;
}

.small {
  min-width: 75px;
  height: 44px;
}

.xsmall {
  height: 32px;
  padding: 7px 8.5px;
}

.tiny {
  height: auto;
  padding: 0 8px;
  border-radius: 4px;
}

.xsmall .label:not(:first-child) {
  display: none;
}

.noMinWidth {
  min-width: initial;
}

.rounded {
  padding: 4px 10px;
  border-radius: 20px;
}

.rounded.primary,
.rounded.inverted {
  background: var(--surface-gray);
  color: var(--surface-white);
}

.rounded.primary:active,
.rounded.inverted:active {
  background: var(--surface-dark);
}

.rounded.secondary {
  background: transparent;
  color: var(--secondary-dark);
  box-shadow: 0 0 0 1px var(--surface-gray) inset;
}

.rounded.secondary:active {
  box-shadow: 0 0 0 1px var(--secondary-dark) inset;
}

.option {
  position: relative;
  height: 56px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--secondary-light);
}

.option:active {
  background-color: var(--secondary-light);
}

.option.selected {
  box-sizing: border-box;
  background-color: var(--surface-white);
  box-shadow: 0 0 0 2px var(--colors-secondary-900) inset;
}

.option.selected::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--colors-secondary-900);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.883728 3.99991L3.94255 6.82344L8.88373 1.64697' stroke='white' stroke-width='1.88235'/%3E%3C/svg%3E%0A");
  background-position: center 4px;
  background-repeat: no-repeat;
  color: var(--surface-white);
  transform: translate(44%, -35%);
}

.blue {
  background: var(--tertiary-blue-dark);
  color: var(--surface-white);
}

@media (hover: hover) {
  .primary:not(:disabled):not(:active):hover {
    background: var(--primary-300-red);
  }

  .secondary:not(:disabled):not(:active):hover {
    box-shadow: 0 0 0 2px var(--surface-gray) inset;
  }

  .inverted:not(:disabled):not(:active):hover {
    background: var(--secondary-light);
  }

  .black:not(:disabled):not(:active):hover {
    color: var(--secondary-gray);
  }

  .rounded.primary:not(:disabled):not(:active):hover,
  .rounded.inverted:not(:disabled):not(:active):hover {
    background: var(--secondary-light);
  }

  .rounded.secondary:not(:disabled):not(:active):hover {
    box-shadow: 0 0 0 1px var(--secondary-light) inset;
  }

  .option:not(:disabled):not(:active):hover {
    background-color: var(--colors-secondary-300);
    color: var(--colors-surface-700);
    cursor: pointer;
  }

  .option.selected:not(:disabled):not(:active):hover {
    background-color: var(--surface-white);
  }

  .blue:not(:disabled):not(:active):hover {
    background: var(--tertiary-blue);
  }
}
