.wrapper {
  position: relative;
  display: block;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 20px;
}

.edgeToEdge {
  padding: 0;
}

@media only screen and (min-width: 908px) {
  .wrapper {
    max-width: 888px;
    padding: 0;
  }
}

@media only screen and (min-width: 1068px) {
  .wrapper {
    max-width: 1048px;
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    max-width: 1180px;
  }
}
