.abwrapper {
  height: 440px;
}

/* large mobile */
@media screen and (min-width: 576px) {
  .abwrapper {
    height: 440px;
  }
}

/* small screen */
@media screen and (min-width: 768px) {
  .abwrapper {
    height: 480px;
  }

  .global-en {
    height: 520px;
  }
}

/* large screen */
@media screen and (min-width: 1200px) {
  .abwrapper {
    height: 620px;
  }

  .global-en {
    height: 570px;
  }
}
