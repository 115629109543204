.primary {
  color: var(--colors-primary-400);
}

.secondary {
  color: var(--secondary-dark);
}

.tertiary {
  color: var(--colors-surface-500);
}

.tertiaryDark {
  color: var(--colors-surface-700);
}

.white {
  color: var(--surface-white);
}

.heading {
  font-weight: bold;
  font-family: Rubik, -apple-system, 'Helvetica Neue', sans-serif;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.largeHeading {
  font-size: 48px;
}

.mediumHeading {
  font-size: 32px;
}

.smallHeading {
  font-size: 24px;
}

.headingTag {
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  line-height: 1;
  letter-spacing: -0.02em;
}

.h1 {
  font-size: var(--font-size-h1);
}

.h2 {
  font-size: var(--font-size-h2);
}

.h3 {
  font-size: var(--font-size-h3);
}

.h4 {
  font-size: var(--font-size-h4);
}

.h5 {
  font-size: var(--font-size-h5);
}

.h6 {
  font-size: var(--font-size-h6);
}

.bodyTag {
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.bold {
  font-weight: bold;
}

.small {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
}

.cta {
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  line-height: 21px;
}

.meta {
  font-size: 12px;
  font-family: 'DM Sans', sans-serif;
  line-height: 16px;
  letter-spacing: -0.01em;
}

.link {
  font-weight: bold;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
}

.linkSmall {
  font-size: 14px;
  line-height: 18px;
}
