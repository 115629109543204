body {
  font-variant: normal !important;
  -webkit-font-feature-settings: unset !important;
  font-feature-settings: unset !important;
}

/* Adyen style overrides */
.adyen-checkout__paywithgoogle button {
  width: 100%;
}

.adyen-checkout__applepay__button {
  width: 100% !important;
  height: 52px;
}

.adyen-checkout__checkbox__input {
  background-color: white;
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
  border-color: #f8284e !important;
  background-color: #f8284e !important;
  box-shadow: none !important;
}

.adyen-checkout__checkbox__input:hover + .adyen-checkout__checkbox__label::after {
  border-color: #f8284e !important;
  transition: background-color 300ms linear;
}

.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label::after {
  border: 1px solid #f8284e !important;
  box-shadow: none !important;
}

.adyen-checkout__button--pay {
  background-color: #f8284e !important;
}

.input-field {
  caret-color: #f8284e !important;
}

.adyen-checkout__threeds2__challenge {
  overflow-x: scroll;
  width: 100% !important;
}
