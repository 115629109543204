.navButtonContainer {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.navButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 18px;
  height: 15px;
}

.navButton .line {
  position: relative;
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all 0.3s linear;
  transform-origin: 0;
}

.navButtonContainerDark .navButton .line {
  background: var(--colors-secondary-900);
}

.navButton .line:first-child {
  transform: rotate(0);
}

.navButtonOpen .line:first-child {
  bottom: 2px;
  transform: rotate(45deg);
}

.navButton .line:nth-child(2) {
  opacity: 1;
}

.navButtonOpen .line:nth-child(2) {
  opacity: 0;
}

.navButton .line:nth-child(3) {
  transform: rotate(0);
}

.navButtonOpen .line:nth-child(3) {
  bottom: -1px;
  transform: rotate(-45deg);
}

.sideNavButton {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.25s ease;
}

.sideNavButtonText {
  display: none;
  padding-left: 8px;
  font-size: 16px;
}

.navButtonContainerDark .sideNavButtonText {
  color: var(--colors-secondary-900);
}

.backButton {
  width: 21px;
  height: 21px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.desktopOnly {
  display: none;
}

@media only screen and (min-width: 768px) {
  .sideNavButtonOpen {
    opacity: 0;
    pointer-events: none;
  }

  .sideNavButtonText {
    display: inline;
  }

  .navButton {
    width: 25px;
    height: 21px;
  }

  .sideNavButtonBack {
    display: none;
  }

  .desktopOnly {
    display: initial;
  }
}
