.businessButton {
  display: none !important; /* to not let antd override it */
}

.businessButtonText {
  padding-left: 8px;
}

.businessButtonDark .businessButtonText {
  color: var(--colors-secondary-900);
}

.businessIcon {
  width: 29px;
  height: 26px;
}

@media (min-width: 1024px) {
  .businessButton {
    display: flex !important;
    align-items: center;
    padding-right: 25px !important;
    font-size: 16px !important;
  }

  .businessButton:global(.ant-btn-link.ant-btn-background-ghost):hover {
    color: #fff !important; /* because antd use !important */
  }

  .businessButtonDark:global(.ant-btn-link.ant-btn-background-ghost):hover {
    color: var(--colors-secondary-900) !important; /* because antd use !important */
  }
}
