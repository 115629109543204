.base {
  margin-top: 50px;
  padding: 0 0 80px;
  background: rgb(39, 41, 45);
  color: rgb(255, 255, 255);
}

.wrapper {
  padding-top: 30px;
}

.base a {
  color: rgb(255, 255, 255);
}

.footerSectionEnroll {
  margin-bottom: 50px;
  padding-top: 30px;
  border-top: 1px solid rgb(61, 62, 66);
}

.navLink {
  display: block;
  margin: 15px 0 0;
  padding: 0 0 15px 5px;
  border-bottom: 1px solid rgb(61, 62, 66);
  font-weight: bold;
  font-size: 15px;
}

.logo {
  max-width: 110px;
  padding-left: 5px;
}

.copyright {
  font-size: 11px;
}

.lineItem {
  padding: 15px 0 15px 0;
  border-bottom: 1px solid rgb(61, 62, 66);
}

.lineItem > :last-child {
  text-align: right;
}

.socialLink {
  margin-right: 10px;
  padding: 5px;
  font-size: 34px;
}

.miscLinks {
  text-align: center;
}

.miscLinks a {
  display: inline-block;
  padding: 5px;
}

/*  @todo import breakpoint */
@media only screen and (min-width: 768px) {
  .base {
    padding: 0 0 50px;
  }

  .footerSectionEnroll {
    margin-bottom: 30px;
    padding-top: 0;
    border-top: none;
  }

  .navLink {
    display: inline-block;
    width: 50%;
    margin: 0;
    padding: 0;
    border-bottom: none;
    color: #ccc;
    font-weight: inherit;
    font-size: inherit;
    transition: all 0.25s ease;
  }

  .lineItem {
    border-bottom: none;
  }

  .socialLink {
    margin-right: 15px;
    padding-left: 0;
    transition: all 0.25s ease;
  }

  .socialLink:hover {
    opacity: 0.8;
  }

  .socialLink:active {
    opacity: 0.5;
  }

  .logo {
    padding-left: 0;
  }

  .miscLinks a {
    padding: 5px 10px 5px 0;
    transition: all 0.25s ease;
  }

  .base a:hover {
    color: rgb(255, 255, 255);
  }

  .navLink:hover,
  .miscLinks a:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  .navLink:active,
  .miscLinks a:active {
    opacity: 0.5;
  }
}
