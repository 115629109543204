.cart {
  width: 21px;
  height: 30px;
  margin-left: 17px;
  cursor: pointer;
}

.iconContainer {
  position: relative;
  z-index: 100;
  color: #fff;
}

.iconContainerDark {
  color: var(--colors-secondary-900);
}

.icon {
  position: absolute;
  top: 3px;
  width: 21px;
  height: 21px;
}

.indicator {
  position: absolute;
  top: 0;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--colors-secondary-900);
  font-size: 8px;
}

.iconContainerDark .indicator {
  background: var(--colors-primary-400);
  color: #fff;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 250px;
  padding-top: 22px;
  padding-bottom: 4px;
  border-radius: 5px;
  text-align: center;
}

.popoverWrapper {
  position: relative;
  display: inline-block;
}

.contentWrapper {
  position: absolute;
  left: -232px;
  z-index: 100;
  margin-top: 10px;
  padding: 20px;
  border-style: solid;
  border-radius: 16px;
  background-color: #fff;
}

.contentWrapper::before {
  content: '';
  position: absolute;
  top: -10px;
  left: calc(50% + 99px);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hideContent {
  visibility: hidden;
}

@media only screen and (min-width: 768px) {
  .cart {
    margin-left: 25px;
  }

  .icon {
    top: 2px;
    width: 24px;
    height: 24px;
  }

  .indicator {
    top: 2px;
    right: -8px;
    width: 15px;
    height: 15px;
  }

  .content {
    width: 280px;
  }
}
