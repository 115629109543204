.base {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
  display: none;
  box-sizing: content-box;
  height: var(--sticky-header-height);
}

.base::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--sticky-header-height);
  background: rgba(255, 255, 255);
  box-shadow: 0 0 10px 1px #3333332e;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .base::after {
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
}

.wrapper {
  height: 100%;
  padding: 0;
}

.logoContainer {
  position: absolute;
  left: 40px;
}

.isVisible {
  display: block;
  animation: slideDown 0.3s ease-out;
}

.visibleDesktopOnly {
  display: none;
}

.innerWrapper {
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 14px;
}

.topNav {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.topNav > a,
.topNav > button {
  display: flex;
  align-items: center;
}

.topNavFunctions {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 768px) {
  .innerWrapper {
    padding: 16px 0;
  }

  .logoContainer {
    position: unset;
  }

  .isVisible {
    display: block;
    animation: slideDown 0.3s ease-out;
  }
}
