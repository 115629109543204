.primary {
  color: var(--colors-primary-400);
}

.light {
  color: var(--colors-surface-000);
}

.dark {
  color: var(--colors-secondary-900);
}

.sm {
  width: 145px;
  height: 18px;
}

.xs {
  width: 110px;
}

.md {
  width: 194px;
  height: 24px;
}
