@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    bottom: -25vh;
  }

  to {
    bottom: 0;
  }
}

@keyframes slideOut {
  from {
    bottom: 0;
  }

  to {
    bottom: -25vh;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  display: flex;
  align-items: flex-end;
  background: hsla(0, 0%, 0%, 0.5);
  animation-name: opacity;
  animation-duration: 0.25s;
  animation-timing-function: ease;
}

.modalAnimateOnClose {
  animation-name: opacityOut;
  animation-duration: 0.25s;
  animation-timing-function: ease;
}

.modalHidden {
  opacity: 0;
}

.modalClose {
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
}

.cardClose {
  position: absolute;
  top: 20px;
  right: 15px;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  transform: rotate(45deg);
}

.container {
  position: relative;
  overflow-y: auto;
  width: 100%;
  max-width: 450px;
  max-height: 90vh;
  padding: 30px 20px 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  color: var(--colors-secondary-900);
  text-align: center;
  animation-name: slideIn;
  animation-duration: 0.25s;
  animation-timing-function: ease;
}

.containerAnimateOnClose {
  animation-name: slideOut;
  animation-duration: 0.25s;
  animation-timing-function: ease;
}

@media (min-width: 768px) {
  .modal {
    align-items: center;
  }

  .container {
    margin: 0 auto;
    padding: 20px 20px 40px;
    border-radius: 8px;
  }

  .cardClose,
  .modalClose {
    top: 10px;
  }
}
