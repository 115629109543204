.marketSwitcher {
  --flag-size: 20px;

  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.25s ease;
}

.flag {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  width: var(--flag-size);
  height: var(--flag-size);
  border-radius: 50%;
  background: #272a2e;
}

.flag > svg {
  object-fit: cover;
  height: 100%;
  margin-left: -6px;
}

.flag > span {
  position: absolute;
  top: 0;
  left: -10px;
  font-size: 54px;
  line-height: var(--flag-size);
}

@media only screen and (min-width: 768px) {
  .marketSwitcher {
    --flag-size: 24px;
  }

  .marketSwitcher:hover {
    opacity: 0.8;
  }

  .marketSwitcher:hover .name {
    text-decoration: underline;
  }

  .marketSwitcher:active {
    opacity: 0.5;
  }
}
